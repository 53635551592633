import './lab-start.scss';
import { getEventProductId, postLab2Exception, getLab2Exception, updateLab2Exception } from '../../utils/api.js';
import React from 'react';
import PropTypes from 'prop-types';
import DateTimePicker from '../../components/date-time-picker';
import SingleSelect from '../../components/single-select';
import Alert from 'react-bootstrap/Alert';
import {
    LAB_FIELDS,
} from './lab-constants';
import * as R from 'ramda';
import Button from 'react-bootstrap/Button';

class LabStart extends React.Component {
    constructor(props) {
        super(props);
        this.state = R.pick(LAB_FIELDS, props.labReducer.currentLab);
        this.state.labAction = 'add';

        this.handleFormChanges = R.bind(this.handleFormChanges, this);
        this.upsertLabStart = R.bind(this.upsertLabStart, this);
        this.setLabStart = R.bind(this.setLabStart, this);
        this.getLabStart = R.bind(this.getLabStart, this);
        this.updateLabStart = R.bind(this.updateLabStart, this);
    }

    componentDidUpdate() {
        if (!this.state.eventProductId &&
            this.props.eventReducer.filteredId &&
            this.props.labReducer.currentLab.product_id
        ) {
            getEventProductId(this.props.eventReducer.filteredId, this.props.labReducer.currentLab.product_id)
                .then((data) => {
                    const idList = [];

                    // The data we want is actually in the `data` property...
                    data = data.data;

                    data.forEach((id) => {
                        idList.push({
                            label: id.event_product_id,
                            value: id.event_product_id,
                        });
                    });
                    this.setState({
                        idList,
                        eventProductId: data[0].event_product_id,
                    }, () => {
                        this.getLabStart(this.state.eventProductId);
                    });
                }).catch(() => {
                    this.setState({
                        notification: {
                            message: 'failed to find event product id',
                            status: 'danger',
                        },
                        eventProductId: 'Not Found',
                    });
                });
        }
    }

    // get lab 2 exception date
    getLabStart(event_product_id) {
        getLab2Exception(event_product_id).then((response) => {
            if (response.length) {
                this.setState({
                    lab_2_date: response[0].start_date,
                    labAction: 'update',
                });
            }
        }).catch(() => {
            this.setState({
                notification: {
                    message: 'Something went wrong',
                    status: 'danger',
                },
            });
        });
    }

    //update lab start
    updateLabStart(event_product_id, start_date) {
        const body = {
            event_product_id,
            start_date,
        };

        updateLab2Exception(event_product_id, body).then((response) => {
            if (response.affectedRows === 1) {
                this.setState({
                    notification: {
                        message: 'Lab Start Updated Successfully',
                        status: 'success',
                    },
                });
            }
        }).catch(() => {
            this.setState({
                notification: {
                    message: 'Something went wrong',
                    status: 'danger',
                },
            });
        });
    }

    // set the lab 2 exception date
    setLabStart(event_product_id, start_date) {
        postLab2Exception({
            event_product_id,
            start_date,
        }).then((response) => {
            if (response.affectedRows === 1) {
                this.setState({
                    notification: {
                        message: 'Lab Start Set Successfully',
                        status: 'success',
                    },
                });
            }
        }).catch(() => {
            this.setState({
                notification: {
                    message: 'Something went wrong',
                    status: 'danger',
                },
            });
        });
    }

    // set the new or updated lab start exception
    upsertLabStart() {
        if (this.state.labAction === 'update') {
            this.updateLabStart(this.state.eventProductId, this.state.lab_2_date);
            return;
        }
        this.setLabStart(this.state.eventProductId, this.state.lab_2_date);
        return;
    }

    handleFormChanges({ target }) {
        this.setState({ [target.id]: target.value });
    }

    render() {
        // console.log('this.props', this.props);
        return (
            <div className="lab-start">
                { this.state.notification && (
                    <Alert variant={ this.state.notification.status }>
                        { this.state.notification.message }
                    </Alert>)
                }
                <h2>{this.props.labReducer.currentLab.name}</h2>
                <h4>Event Product Id: { this.state.eventProductId }</h4>
                { this.state.idList && this.state.idList.length > 1 && (
                    <SingleSelect
                        id="eventProductId"
                        label="Selected Event Product Id: "
                        className="id-selector"
                        selected={ this.state.eventProductId }
                        options={ this.state.idList }
                        onChange={ this.handleFormChanges }
                    />
                )}
                <p>Event Start: {this.props.labReducer.currentLab.live_event_start}</p>
                <p>Event End: {this.props.labReducer.currentLab.live_event_end}</p>

                { this.state.eventProductId && (
                    <div>
                        <div>
                            <h5>Select the lab 2 start date and time</h5>
                            <p className="utc-disclaimer">* Times are converted to UTC time</p>
                            <DateTimePicker
                                id="lab_2_date"
                                value={ this.state.lab_2_date }
                                onChange={ this.handleFormChanges }
                            />
                        </div>
                        <Button variant="success" onClick={ this.upsertLabStart }>
                            { this.state.labAction === 'update' ? 'Update' : 'Create' }
                        </Button>
                    </div>)
                }
            </div>
        );
    }
}

LabStart.propTypes = {
    appReducer: PropTypes.object,
    labReducer: PropTypes.object,
    eventReducer: PropTypes.object,
};

export default LabStart;
